import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SetupService } from 'src/app/users/service/operations/setup.service';
import { finalize } from 'rxjs/operators';
import {
  DEFAULT_HOUR_LIST,
  DEFAULT_MIN_LIST,
  DEFAULT_SERVICE_VIEW_WEEKS,
  TIME_SLOTS,
  VIEW_WEEKS,
} from 'src/app/constants';

@Component({
  selector: 'app-service-setup',
  templateUrl: './service-setup.component.html',
  styleUrls: ['./service-setup.component.scss'],
})
export class ServiceSetupComponent implements OnInit {
  activeId: number = 1;
  activateDisable: boolean;
  isHeading: string = 'CREATE SERVICE SETUP';
  serviceSetUpForm: FormGroup;
  serviceOption: any[] = [];
  setupSaveLoader: boolean = false;
  isVisibleActionButton: boolean = false;
  time: string[] = TIME_SLOTS;
  viewWeeks: number[] = VIEW_WEEKS;
  minList: number[] = DEFAULT_MIN_LIST;
  hourList: number[] = DEFAULT_HOUR_LIST;
  timeLimit: number[] = [];
  itemToUpdate: any;
  serviceTypeLoader = false;
  timezone: any;
  pagination = {
    page: 1,
    limit: 10,
    sort: '{"_id": -1}',
  };
  constructor(
    private toastService: ToastrService,
    private service: SetupService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.initializeForm();
    this.loadInitialData();
  }

  onTabChange(event) {
    if (this.activeId === 1) {
      this.initializeForm();
      this.loadInitialData();
      this.serviceSetUpForm.enable();
    }
  }

  private initializeForm(): void {
    this.activateDisable = false;
    this.activeId = 1;
    this.serviceSetUpForm = this.fb.group({
      _id: [''],
      serviceType: [null, [Validators.required]],
      serviceName: ['', [Validators.required]],
      serviceViewWeeks: [DEFAULT_SERVICE_VIEW_WEEKS, [Validators.required]],
      standBySlot: [false, [Validators.required]],
      StandByActivationTime: [0],
      showServiceEndTime: [false, [Validators.required]],
      cancelService: [false, [Validators.required]],
      cutOffTimeRequired: [true, [Validators.required]],
      cutOffDurationBeforeStartTime: [true],
      cutOffConfiguration: this.fb.group({
        cutOffDays: [0],
        cutOffHours: [0],
        cutOffMinutes: [0],
      }),
      fixedCutOffTime: [null],
    });
  }

  private loadInitialData() {
    this.isHeading = 'CREATE SERVICE SETUP';
    this.isVisibleActionButton = true;
    this.activateDisable = false;
    this.timeLimit = Array.from({ length: 24 }, (_, i) => i + 1);
    this.serviceOption = [];
    this.getServiceType();
  }

  getServiceType(): void {
    this.serviceTypeLoader = true;
    const subscribe = this.service
      .getServiceType(this.pagination)
      .pipe(
        finalize(() => {
          subscribe.unsubscribe();
          this.serviceTypeLoader = false;
        })
      )
      .subscribe((response: any) => {
        const { data, totalRecords } = response.data;
        this.serviceOption = [...this.serviceOption, ...data];
      });
  }

  loadMoreServiceTypes(): void {
    this.pagination.page++;
    this.getServiceType();
  }

  onViewBtn(event) {
    this.handleServiceSetup(event, 'view');
  }

  onEditBtn(event) {
    this.handleServiceSetup(event, 'edit');
  }

  handleServiceSetup(event, mode: 'view' | 'edit') {
    this.serviceSetUpForm.reset();
    this.service.getSingleServiceSetup(event._id, this.timezone).subscribe(
      (response: any) => {
        if (mode === 'view') {
          this.activeId = 2;
          this.serviceSetUpForm.disable();
          this.isHeading = 'VIEW SERVICE SETUP';
          this.isVisibleActionButton = false;
        } else if (mode === 'edit') {
          this.activateDisable = true;
          this.isHeading = 'EDIT SERVICE SETUP';
          this.activeId = 2;
          this.isVisibleActionButton = true;
          this.itemToUpdate = response.data;
          this.serviceSetUpForm.enable();
          this.serviceSetUpForm.get('serviceType').disable();
        }
        this.setFormGroupValue(response.data);
      },
      (error) => {
        const errorMessage =
          error?.error?.error?.message || 'Service Not Found!';
        this.toastService.error(errorMessage);
      }
    );
  }

  saveSetupDetails() {
    if (this.serviceSetUpForm.valid) {
      this.setupSaveLoader = true;
      const payload = { ...this.serviceSetUpForm.value };
      delete payload._id;
      payload.fixedCutOffTime = this.parseTime(payload.fixedCutOffTime);
      payload.timezone = this.timezone;
      payload.cutOffTimeRequired === false &&
        (payload.cutOffDurationBeforeStartTime = false);
      this.service
        .saveServiceSetup(payload)
        .pipe(
          finalize(() => {
            this.setupSaveLoader = false;
          })
        )
        .subscribe(
          (res) => {
            this.toastService.success('Service setup saved successfully!');
            this.initializeForm();
          },
          (err) => {
            const errorMessage =
              err?.error?.error?.message || 'Failed to save service setup';
            this.toastService.error(errorMessage);
          }
        );
    } else {
      this.toastService.error(
        'Please complete all required fields before submitting',
        'Missing Information'
      );
    }
  }

  cancel() {
    this.initializeForm();
  }

  setFormGroupValue(data) {
    this.serviceSetUpForm.patchValue({
      _id: data._id,
      serviceType: data.serviceType,
      serviceName: data.serviceName,
      serviceViewWeeks: data.serviceViewWeeks,
      standBySlot: data.standBySlot,
      StandByActivationTime: data.StandByActivationTime,
      showServiceEndTime: data.showServiceEndTime,
      cancelService: data.cancelService,
      cutOffTimeRequired: data.cutOffTimeRequired,
      cutOffDurationBeforeStartTime: data.cutOffDurationBeforeStartTime,
      cutOffConfiguration: {
        cutOffDays: data.cutOffConfiguration.cutOffDays,
        cutOffHours: data.cutOffConfiguration.cutOffHours,
        cutOffMinutes: data.cutOffConfiguration.cutOffMinutes,
      },
      fixedCutOffTime: `${
        data.fixedCutOffTime.hour
      }:${data.fixedCutOffTime.minute.toString().padStart(2, '0')}`,
    });
  }

  updateSetupDetails() {
    if (this.serviceSetUpForm.valid) {
      this.setupSaveLoader = true;
      const { _id, ...payload } = this.serviceSetUpForm.value;

      payload.fixedCutOffTime = this.parseTime(payload.fixedCutOffTime);
      payload.timezone = this.timezone;

      if (this.itemToUpdate?.serviceName === payload.serviceName) {
        delete payload.serviceName;
      }

      if (!payload.cutOffTimeRequired) {
        payload.cutOffDurationBeforeStartTime = false;
      }

      this.service
        .updateServiceSetup(_id, payload)
        .pipe(finalize(() => (this.setupSaveLoader = false)))
        .subscribe(
          () => {
            this.toastService.success('Service setup updated successfully!');
            this.initializeForm();
          },
          (err) => {
            const errorMessage =
              err?.error?.error?.message || 'Failed to update service setup';
            this.toastService.error(errorMessage);
          }
        );
    } else {
      this.toastService.error(
        'Please complete all required fields before submitting',
        'Missing Information'
      );
    }
  }

  private parseTime(input: string) {
    if (!input) {
      return { hour: 0, minute: 0 };
    }

    const [hour, minute] = input.split(':').map(Number);
    return { hour: hour || 0, minute: minute || 0 };
  }
}
