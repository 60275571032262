<div class="card">
  <h5 class="card-header bg-theme">View</h5>
  <div class="card-body">
    <div class="row mb-3">
      <div class="col">
        <div class="table-responsive rounded border-theme">
          <table class="table m-0">
            <tr>
              <th>BALLOT NAME</th>
              <td>{{ this.ballot.ballotName }}</td>
            </tr>
            <tr>
              <th>BALLOT FOR PERIOD</th>
              <td>
                {{ this.ballot.ballotStartDate | date: "dd-MM-yyyy" }} to
                {{ this.ballot.ballotEndDate | date: "dd-MM-yyyy" }}
              </td>
            </tr>
            <tr>
              <th>OPENING DATE FOR APPLICATION</th>
              <td>
                {{ this.ballot?.applicationOpenDateTime | date: "dd-MM-yyyy" }},
                {{ this.ballot?.applicationOpenDateTime | date: "HH:mm"}}
              </td>
            </tr>
            <tr>
              <th>CLOSING DATE FOR APPLICATION</th>
              <td>
                {{ this.ballot.applicationCloseDateTime | date: "dd-MM-yyyy" }},
                {{ this.ballot.applicationCloseDateTime | date: "HH:mm"}}
              </td>
            </tr>
            <tr>
              <th>RELEASE TYPE</th>
              <td>
                {{ this.ballot.resultRelease == "1" ? 'Auto (' + (this.ballot.resultReleaseDateTime | date: 'dd-MM-yyyy, HH:mm') + ')' : "Manual" }}
              </td>
            </tr>
            <tr>
              <th>BALLOT TYPE</th>
              <td>
                {{
                  this.ballot.leaveType == 1
                    ? "Week Ballot Slot"
                    : "Day Ballot Slot"
                }}
              </td>
            </tr>
            <tr *ngIf="this.ballot?.fixedBallotingLeaveType">
              <th>Leave Type / Quota</th>
              <td>
                {{ this.ballot.leaveTypeId?.name }} / Quota:
                {{ this.ballot.totalQuota }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-4 mb-3" *ngIf="ballot?.isAutoAssign">
        <div class="card border-theme">
          <div class="card-body">
            <h6 class="card-title mb-3">Ratio for balance quota assignment</h6>
            <div class="d-flex text-center justify-content-between">
              <p class="text-center m-0 text-muted">
                Unassigned<br /><span class="h2 text-dark">{{
                  ballot.totUN ? ballot.totUN : 0
                }}</span>
              </p>
              <p class="text-center m-0 text-muted">
                Balance Quota<br /><span class="h2 text-dark">{{
                  ballot.TotBQ ? ballot.TotBQ : 0
                }}</span>
              </p>
              <p
                class="text-center m-0 text-muted"
                title="Unassigned / Balance Quota = Ratio"
              >
                Ratio<br /><span class="h2 text-dark">{{
                  ballot.assignRatio | number
                }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="border-theme rounded">
          <div
            class="table-responsive table-sticky"
            [class.auto]="ballot?.isAutoAssign"
          >
            <table
              class="table table-striped border-left m-0"
              *ngIf="!ballot?.isAutoAssign"
            >
              <thead>
                <tr>
                  <th scope="col" class="fix border-bottom-0 border-top-0">
                    OPS GROUP
                  </th>
                  <th scope="col" class="fix border-bottom-0 border-top-0">
                    OPS TEAM
                  </th>
                  <th
                    scope="col"
                    class="noWrap"
                    *ngFor="let item of slotWeeks; let i = index"
                  >
                    SLOT {{ i + 1 }}<br />
                    <span class="text-muted h6">
                      <ng-container *ngIf="ballot.leaveType == '1'">
                        {{ item[0] | date: "dd MMM" }} -
                        {{ item[1] | date: "dd MMM" }}
                      </ng-container>
                      <ng-container *ngIf="ballot.leaveType == '2'">
                        {{ item | date: "dd MMM" }}
                      </ng-container>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of allSlotArr">
                  <th scope="row" class="fix">{{ item.opsGrp }}</th>
                  <td class="fix">{{ item.team }}</td>

                  <td *ngFor="let el of item.slots; let i = index">
                    <input
                      type="text"
                      class="form-control"
                      [value]="el.value"
                      readonly
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              class="table table-striped border-left m-0"
              *ngIf="ballot?.isAutoAssign"
            >
              <thead>
                <tr>
                  <th scope="col" class="fix border-bottom-0 border-top-0">
                    OPS GROUP
                  </th>
                  <th scope="col" class="fix border-bottom-0 border-top-0">
                    OPS TEAM
                  </th>
                  <th
                    scope="col"
                    class="fix autofix border-bottom-0 border-top-0"
                  >
                    TOTAL
                    <div>
                      <span class="box-label text-muted">BQ</span>
                      <span class="box-label text-muted ml-1">U</span>
                    </div>
                  </th>
                  <th
                    scope="col noWrap"
                    *ngFor="let item of slotWeeks; let i = index"
                  >
                    SLOT {{ i + 1 }}<br />
                    <span class="h6 text-muted">
                      <ng-container *ngIf="ballot.leaveType == '1'">
                        {{ item[0] | date: "dd MMM" }} -
                        {{ item[1] | date: "dd MMM" }}
                      </ng-container>
                      <ng-container *ngIf="ballot.leaveType == '2'">
                        {{ item | date: "dd MMM" }}
                      </ng-container>
                    </span>

                    <div>
                      <span class="box-label text-muted">BQ</span>
                      <span class="box-label text-muted ml-1">TBA</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of allSlotArr">
                  <th scope="row" class="fix">{{ item.opsGrp }}</th>
                  <td class="fix">{{ item.team }}</td>
                  <td class="fix autofix">
                    <div class="d-flex">
                      <input
                        type="text"
                        class="form-control box"
                        [value]="item.totalBallotBalance"
                        readonly
                      />
                      <input
                        type="text"
                        class="form-control box ml-1"
                        [value]="item.totalUnassignedIs"
                        readonly
                      />
                    </div>
                  </td>
                  <td scope="col noWrap" *ngFor="let el of slotWeeks; let i = index" >
                  <span class="h6 text-muted">
                      <div class="d-flex">
                        <input
                          type="text"
                          class="form-control box"
                          [(ngModel)]="item.slots[i].value"
                          readonly
                        />
                        <input
                          type="text"
                          class="form-control box ml-1"
                          [(ngModel)]="item.slots[i].balanceToBeAssigned"
                          readonly
                        />
                      </div>
                  </span>
                </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-3" *ngIf="ballot?.isAutoAssign">
      <div class="col-md-12">
        <div class="card">
          <h6 class="card-header bg-theme">
            ELIGIBLE STAFF FOR AUTO-ASSIGNMENT
          </h6>
          <div class="card-body p-0">
            <table class="table" *ngIf="ballot?.eligibleStaffsForAuto">
              <thead>
                <tr>
                  <th>STAFF NAME</th>
                  <th>BALLOT BALANCE</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of ballot?.eligibleStaffsForAuto">
                  <td>{{ item.name }}</td>
                  <td>{{ item.ballotLeaveBalance }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-3" *ngIf="ballot?.maxSegment?.length>0">
      <div class="col-md-12">
        <div class="card">
          <h6 class="card-header bg-theme">MAXIMUM SUBMISSIONS IN A SEGMENT</h6>
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table m-0">
                <thead>
                  <tr>
                    <th scope="col">SEGMENT NO</th>
                    <th scope="col">START DATE</th>
                    <th scope="col">END DATE</th>
                    <th scope="col">MAXIMUM SLOT PER STAFF</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of this.ballot.maxSegment">
                    <td>{{ item.segmentNo }}</td>
                    <td>
                      {{ item.startDate | date: "dd-MM-yyyy" }}
                    </td>
                    <td>
                      {{ item.endDate | date: "dd-MM-yyyy" }}
                    </td>
                    <td>{{ item.maxBallot }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-3" *ngIf="ballot.maxConsecutiveBallot">
      <div class="col-md-12">
        <div class="card">
          <h6 class="card-header bg-theme">MAXIMUM CONSECUTIVE BALLOTS</h6>
          <div class="card-body">
            <div class="form-group m-0">
              <label class="font-weight-500"
                >Maximum consecutive ballots per staff</label
              >
              <input
                type="text"
                class="form-control w-25"
                [value]="ballot.maxConsecutiveBallot"
                readonly
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-3" *ngIf="ballot?.staffRestriction?.length>0">
      <div class="col-md-12">
        <div class="card">
          <h6 class="card-header bg-theme">
            STAFF RESTRICTED FROM PARTICULAR WEEKS
          </h6>
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table m-0">
                <thead>
                  <tr>
                    <th scope="col">SELECTED DATE</th>
                    <th scope="col">STAFF LIST</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of ballot.staffRestriction">
                    <td>
                      {{item.slot}}
                      <!-- {{ item.startDate | date: "dd-MMM" }} to
                      {{ item.endDate | date: "dd-MMM" }} -->
                    </td>
                    <td class="comma">
                      <span *ngFor="let el of item.userList">{{
                        el.label
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div class="d-flex">
      <button
        type="button"
        class="align-items-center btn btn-outline-dark d-flex"
        (click)="backbtn()"
      >
        <span class="material-icons mr-1"> arrow_back </span>
        <span>Back</span>
      </button>
    </div>
  </div>
</div>
