<mat-horizontal-stepper [linear]="isLinear" #stepper>
  <div class="loading-class" *ngIf="loader">
    <img src="./assets/images/loading.gif" />
    <div class="text-center">Please wait, data is loading...</div>
  </div>
  <mat-step [stepControl]="ballotdata">
    <form [formGroup]="ballotdata">
      <ng-template matStepLabel>ENTER BALLOT DATA </ng-template>
      <div class="card">
        <div class="card-header bg-theme">ENTER BALLOT DATA</div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="font-weight-500">Ballot Name<span class="field-mandatory">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="ballotName"
                  placeholder="Please enter ballot name"
                  [ngClass]="{
                    'is-invalid':
                      (ballotdataSubmitted &&
                        ballotdata.controls.ballotName.errors) ||
                      (!ballotdata.controls.ballotName.pristine &&
                        ballotdata.controls.ballotName.invalid)
                  }"
                />

                <!-- Validation messages -->
                <div
                  class="invalid-feedback"
                  *ngIf="
                    (ballotdataSubmitted &&
                      ballotdata.controls.ballotName.errors) ||
                    (!ballotdata.controls.ballotName.pristine &&
                      ballotdata.controls.ballotName.invalid)
                  "
                >
                  <div *ngIf="ballotdata.controls.ballotName.errors.required">
                    Ballot Name is required
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <div class="form-grou">
                <label class="font-weight-500">Opening date<span class="field-mandatory">*</span></label>
                <input
                  class="form-control"
                  (click)="d1.toggle()"
                  (close)="checkTime()"
                  [outsideDays]="hidden"
                  container="body"
                  placeholder="Select date"
                  name="dp"
                  ngbDatepicker
                  #d1="ngbDatepicker"
                  formControlName="openDate"
                  [minDate]="futureMinDate"
                  [ngClass]="{
                    'is-invalid':
                      (ballotdataSubmitted &&
                        ballotdata.controls.openDate.errors) ||
                      (!ballotdata.controls.openDate.pristine &&
                        ballotdata.controls.openDate.invalid)
                  }"
                />

                <!-- Validation messages -->
                <div
                  class="invalid-feedback"
                  *ngIf="
                    (ballotdataSubmitted &&
                      ballotdata.controls.openDate.errors) ||
                    (!ballotdata.controls.openDate.pristine &&
                      ballotdata.controls.openDate.invalid)
                  "
                >
                  <div *ngIf="ballotdata.controls.openDate.errors.required">
                    Opening Date is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label class="font-weight-500">Opening Time<span class="field-mandatory">*</span></label>
                <ng-select
                  [items]="time"
                  appendTo="body"
                  formControlName="openTime"
                  (change)="checkTime()"
                  placeholder="Select time"
                  [ngClass]="{
                    'is-invalid':
                      (ballotdataSubmitted &&
                        ballotdata.controls.openTime.errors) ||
                      (!ballotdata.controls.openTime.pristine &&
                        ballotdata.controls.openTime.invalid)
                  }"
                >
                </ng-select>
                <!-- Validation messages -->
                <div
                  class="invalid-feedback"
                  *ngIf="
                    (ballotdataSubmitted &&
                      ballotdata.controls.openTime.errors) ||
                    (!ballotdata.controls.openTime.pristine &&
                      ballotdata.controls.openTime.invalid)
                  "
                >
                  <div *ngIf="ballotdata.controls.openTime.errors.required">
                    Opening Time is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label class="font-weight-500">Closing date<span class="field-mandatory">*</span></label>
                <input
                  class="form-control"
                  (click)="d2.toggle()"
                  [outsideDays]="hidden"
                  container="body"
                  placeholder="Select date"
                  name="dp"
                  [minDate]="closingMinDate"
                  ngbDatepicker
                  #d2="ngbDatepicker"
                  formControlName="closeDate"
                  [ngClass]="{
                    'is-invalid':
                      (ballotdataSubmitted &&
                        ballotdata.controls.closeDate.errors) ||
                      (!ballotdata.controls.closeDate.pristine &&
                        ballotdata.controls.closeDate.invalid)
                  }"
                />

                <!-- Validation messages -->
                <div
                  class="invalid-feedback"
                  *ngIf="
                    (ballotdataSubmitted &&
                      ballotdata.controls.closeDate.errors) ||
                    (!ballotdata.controls.closeDate.pristine &&
                      ballotdata.controls.closeDate.invalid)
                  "
                >
                  <div *ngIf="ballotdata.controls.closeDate.errors.required">
                    Closing date Date is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label class="font-weight-500">Closing Time<span class="field-mandatory">*</span></label>
                <ng-select
                  [items]="time"
                  appendTo="body"
                  formControlName="closeTime"
                  placeholder="Select time"
                  [ngClass]="{
                    'is-invalid':
                      (ballotdataSubmitted &&
                        ballotdata.controls.closeTime.errors) ||
                      (!ballotdata.controls.closeTime.pristine &&
                        ballotdata.controls.closeTime.invalid)
                  }"
                >
                </ng-select>
                <!-- Validation messages -->
                <div
                  class="invalid-feedback"
                  *ngIf="
                    (ballotdataSubmitted &&
                      ballotdata.controls.closeTime.errors) ||
                    (!ballotdata.controls.closeTime.pristine &&
                      ballotdata.controls.closeTime.invalid)
                  "
                >
                  <div *ngIf="ballotdata.controls.closeTime.errors.required">
                    Closing Time is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label class="font-weight-500">Ballot period Start<span class="field-mandatory">*</span></label>
                <input
                  class="form-control"
                  (click)="d3.toggle()"
                  placeholder="Select date"
                  [outsideDays]="hidden"
                  container="body"
                  [minDate]="this.ballotdata.get('closeDate').value"
                  name="ballotStartDate"
                  ngbDatepicker
                  #d3="ngbDatepicker"
                  formControlName="ballotStartDate"
                  [ngClass]="{
                    'is-invalid':
                      (ballotdataSubmitted &&
                        ballotdata.controls.ballotStartDate.errors) ||
                      (!ballotdata.controls.ballotStartDate.pristine &&
                        ballotdata.controls.ballotStartDate.invalid)
                  }"
                />

                <!-- Validation messages -->
                <div
                  class="invalid-feedback"
                  *ngIf="
                    (ballotdataSubmitted &&
                      ballotdata.controls.ballotStartDate.errors) ||
                    (!ballotdata.controls.ballotStartDate.pristine &&
                      ballotdata.controls.ballotStartDate.invalid)
                  "
                >
                  <div
                    *ngIf="ballotdata.controls.ballotStartDate.errors.required"
                  >
                    Ballot Start Date is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label class="font-weight-500">Ballot period End<span class="field-mandatory">*</span></label>
                <input
                  class="form-control"
                  (click)="d4.toggle()"
                  placeholder="Select date"
                  [outsideDays]="hidden"
                  container="body"
                  [minDate]="this.ballotdata.get('ballotStartDate').value"
                  name="ballotEndDate"
                  ngbDatepicker
                  #d4="ngbDatepicker"
                  formControlName="ballotEndDate"
                  [ngClass]="{
                    'is-invalid':
                      (ballotdataSubmitted &&
                        ballotdata.controls.ballotEndDate.errors) ||
                      (!ballotdata.controls.ballotEndDate.pristine &&
                        ballotdata.controls.ballotEndDate.invalid)
                  }"
                />

                <!-- Validation messages -->
                <div
                  class="invalid-feedback"
                  *ngIf="
                    (ballotdataSubmitted &&
                      ballotdata.controls.ballotEndDate.errors) ||
                    (!ballotdata.controls.ballotEndDate.pristine &&
                      ballotdata.controls.ballotEndDate.invalid)
                  "
                >
                  <div
                    *ngIf="ballotdata.controls.ballotEndDate.errors.required"
                  >
                    Ballot End Date is required
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-md-6">
              <div class="card">
                <div class="card-body">
                  <div class="form-group m-0">
                    <label class="mb-0 mr-4 font-weight-500"
                      >Select Leave Type<span class="field-mandatory">*</span>:
                    </label>
                    <div
                      class="custom-control custom-radio custom-control-inline"
                    >
                      <input
                        type="radio"
                        id="customRadioInline1"
                        name="leaveType"
                        class="custom-control-input"
                        formControlName="leaveType"
                        value="1"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline1"
                        >Week Ballot Slot</label
                      >
                    </div>
                    <div
                      class="custom-control custom-radio custom-control-inline"
                    >
                      <input
                        type="radio"
                        id="customRadioInline2"
                        name="leaveType"
                        class="custom-control-input"
                        formControlName="leaveType"
                        value="2"
                      />
                      <label
                        class="custom-control-label"
                        for="customRadioInline2"
                        >Day Ballot Slot</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card">
                <div class="card-body">
                  <div
                    class="form-group m-0"
                    *ngIf="this.ballotdata.get('leaveType').value == '1'"
                  >
                    <label for="" class="mb-0 mr-4 font-weight-500"
                      >Block Leave Configuration: {{ this.blockLeave }}
                    </label>
                  </div>

                  <ng-container
                    *ngIf="this.ballotdata.get('leaveType').value == '2'"
                  >
                    <div class="form-group form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="fixedBallotingLeaveType"
                        name="fixedBallotingLeaveType"
                        formControlName="fixedBallotingLeaveType"
                        [ngClass]="{
                          'is-invalid':
                            ballotdataSubmitted &&
                            ballotdata.errors?.fixedNotChecked
                        }"
                      />

                      <label
                        class="form-check-label font-weight-500"
                        for="fixedBallotingLeaveType"
                        >Fixed Balloting Leave Type<span class="field-mandatory">*</span></label
                      >
                      <div
                          class="invalid-feedback d-block"
                          *ngIf="
                            ballotdataSubmitted &&
                            ballotdata.errors?.fixedNotChecked
                          "
                        >
                          <div>Please select Fixed Balloting Leave Type</div>
                        </div>
                    </div>
                    <div
                      class="form-group row"
                      *ngIf="
                        this.ballotdata.get('fixedBallotingLeaveType').value
                      "
                    >
                      <div class="col-sm-6">
                        <input
                          name="totalQuota"
                          formControlName="totalQuota"
                          type="text"
                          id="Quota1"
                          class="form-control d-inline-block w-25"
                          [ngClass]="{
                            'is-invalid':
                              ballotdataSubmitted &&
                              ballotdata.errors?.quotaValueNull
                          }"
                        />
                        <label
                          for="Quota1"
                          class="d-inline-block pl-2 col-form-label font-weight-500"
                          >Quota</label
                        >
                        <!-- Validation messages -->
                        <div
                          class="invalid-feedback"
                          *ngIf="
                            ballotdataSubmitted &&
                            ballotdata.errors?.quotaValueNull
                          "
                        >
                          <div>Please enter Quota</div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group m-0">
                        <label for="" class="mb-0 mr-4 font-weight-500"
                          >Results Release setup<span class="field-mandatory">*</span>:
                        </label>
                        <div
                          class="custom-control custom-radio custom-control-inline"
                        >
                          <input
                            type="radio"
                            id="customRadioInline3"
                            name="resultRelease"
                            class="custom-control-input"
                            formControlName="resultRelease"
                            value="1"
                            [ngClass]="{
                              'is-invalid':
                                (ballotdataSubmitted &&
                                  ballotdata.controls.resultRelease.errors) ||
                                (!ballotdata.controls.resultRelease.pristine &&
                                  ballotdata.controls.resultRelease.invalid)
                            }"
                          />
                          <label
                            class="custom-control-label"
                            for="customRadioInline3"
                            >Auto</label
                          >
                        </div>
                        <div
                          class="custom-control custom-radio custom-control-inline"
                          [ngClass]="{
                            'is-invalid':
                              (ballotdataSubmitted &&
                                ballotdata.controls.resultRelease.errors) ||
                              (!ballotdata.controls.resultRelease.pristine &&
                                ballotdata.controls.resultRelease.invalid)
                          }"
                        >
                          <input
                            type="radio"
                            id="customRadioInline4"
                            name="resultRelease"
                            class="custom-control-input"
                            formControlName="resultRelease"
                            value="2"
                            [ngClass]="{
                              'is-invalid':
                                (ballotdataSubmitted &&
                                  ballotdata.controls.resultRelease.errors) ||
                                (!ballotdata.controls.resultRelease.pristine &&
                                  ballotdata.controls.resultRelease.invalid)
                            }"
                          />
                          <label
                            class="custom-control-label"
                            for="customRadioInline4"
                            >Manual</label
                          >
                        </div>

                        <!-- Validation messages -->
                        <div
                          class="invalid-feedback"
                          *ngIf="
                            (ballotdataSubmitted &&
                              ballotdata.controls.resultRelease.errors) ||
                            (!ballotdata.controls.resultRelease.pristine &&
                              ballotdata.controls.resultRelease.invalid)
                          "
                        >
                          <div
                            *ngIf="
                              ballotdata.controls.resultRelease.errors.required
                            "
                          >
                            Result Release Type is required
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-md-6"
                      *ngIf="this.ballotdata.get('resultRelease').value == '1'"
                    >
                      <div class="card">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="" class="font-weight-500"
                                  >Select Date<span class="field-mandatory">*</span></label
                                >
                                <input
                                  class="form-control"
                                  (click)="d5.toggle()"
                                  (closed)="resultReleaseTimeCheck()"
                                  placeholder="Select date"
                                  name="dp"
                                  ngbDatepicker
                                  [minDate]="
                                    ballotdata.controls.closeDate.value
                                  "
                                  [maxDate]="
                                    ballotdata.controls.ballotStartDate.value
                                  "
                                  #d5="ngbDatepicker"
                                  formControlName="resultReleaseDate"
                                  [ngClass]="{
                                    'is-invalid':
                                      (ballotdataSubmitted &&
                                        ballotdata.controls.resultReleaseDate
                                          .errors) ||
                                      (!ballotdata.controls.resultReleaseDate
                                        .pristine &&
                                        ballotdata.controls.resultReleaseDate
                                          .invalid)
                                  }"
                                />

                                <!-- Validation messages -->
                                <div
                                  class="invalid-feedback"
                                  *ngIf="
                                    (ballotdataSubmitted &&
                                      ballotdata.controls.resultReleaseDate
                                        .errors) ||
                                    (!ballotdata.controls.resultReleaseDate
                                      .pristine &&
                                      ballotdata.controls.resultReleaseDate
                                        .invalid)
                                  "
                                >
                                  <div
                                    *ngIf="
                                      ballotdata.controls.resultReleaseDate
                                        .errors.required
                                    "
                                  >
                                    Release Date is required
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="" class="font-weight-500"
                                  >Select Time<span class="field-mandatory">*</span></label
                                >
                                <ng-select
                                  [items]="time"
                                  formControlName="resultReleaseTime"
                                  (change)="resultReleaseTimeCheck()"
                                  [ngClass]="{
                                    'is-invalid':
                                      (ballotdataSubmitted &&
                                        ballotdata.controls.resultReleaseTime
                                          .errors) ||
                                      (!ballotdata.controls.resultReleaseTime
                                        .pristine &&
                                        ballotdata.controls.resultReleaseTime
                                          .invalid)
                                  }"
                                ></ng-select>

                                <!-- Validation messages -->
                                <div
                                  class="invalid-feedback"
                                  *ngIf="
                                    (ballotdataSubmitted &&
                                      ballotdata.controls.resultReleaseTime
                                        .errors) ||
                                    (!ballotdata.controls.resultReleaseTime
                                      .pristine &&
                                      ballotdata.controls.resultReleaseTime
                                        .invalid)
                                  "
                                >
                                  <div
                                    *ngIf="
                                      ballotdata.controls.resultReleaseTime
                                        .errors.required
                                    "
                                  >
                                    Release Date is required
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="d-flex">
            <button
              type="button"
              class="bg-theme border-0 btn d-flex align-items-center"
              (click)="ballotdataNext()"
            >
              <span>Next</span>
              <span class="material-icons ml-1"> arrow_forward </span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="slotQuotaSetup">
    <form [formGroup]="slotQuotaSetup">
      <ng-template matStepLabel
        >{{ this.autoBallot ? "AUTO-ASSIGN SETUP" : "SLOTS & QUOTAS SETUP" }}
      </ng-template>
      <div class="card">
        <div class="card-header bg-theme">
          {{
            this.autoBallot
              ? "AUTO ASSIGN BALLOTING EXERCISE"
              : "SLOTS & QUOTAS SETUP"
          }}
        </div>
        <div class="card-body mh-3">
          <div class="row">
            <div class="col-md-4 align-self-end">
              <div class="form-group m-0">
                <label class="font-weight-500">Select Ops group<span class="field-mandatory">*</span></label>
                <ng-select
                  [items]="opsList"
                  [multiple]="true"
                  [closeOnSelect]="false"
                  [searchable]="true"
                  bindLabel="opsGroupName"
                  formControlName="opsGroupId"
                  (change)="getOpsGrp()"
                  placeholder="Please select or search ops group"
                  [ngClass]="{
                    'is-invalid':
                      (slotQuotaSetupSubmitted &&
                        slotQuotaSetup.controls.opsGroupId.errors) ||
                      (!slotQuotaSetup.controls.opsGroupId.pristine &&
                        slotQuotaSetup.controls.opsGroupId.invalid)
                  }"
                >
                </ng-select>

                <!-- Validation messages -->
                <div
                  class="invalid-feedback"
                  *ngIf="
                    (slotQuotaSetupSubmitted &&
                      slotQuotaSetup.controls.opsGroupId.errors) ||
                    (!slotQuotaSetup.controls.opsGroupId.pristine &&
                      slotQuotaSetup.controls.opsGroupId.invalid)
                  "
                >
                  <div
                    *ngIf="slotQuotaSetup.controls.opsGroupId.errors.required"
                  >
                    Ops group is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 align-self-end">
              <ng-template [ngIf]="this.opsGrp != null">
                <div
                  class="labels my-1"
                  *ngFor="let item of opsGrp; let i = index"
                >
                  <span class="text">{{ item.opsGroupName }}</span>
                  <!-- <span
                    class="material-icons bg-danger rounded btn-close"
                    (click)="clearLabel(i)"
                  >
                    clear
                  </span> -->
                </div>
              </ng-template>
            </div>
          </div>
          <hr />
          <div class="row" *ngIf="opsGrp">
            <div class="col-md-4 mb-3" *ngIf="autoBallot">
              <div class="card border-theme">
                <div class="card-body">
                  <h6 class="card-title mb-3">
                    Ratio for balance quota assignment
                  </h6>
                  <div class="d-flex text-center justify-content-between">
                    <p class="text-center m-0 text-muted">
                      Unassigned<br /><span class="h2 text-dark">{{
                        this.autoBallot.data.totUN
                      }}</span>
                    </p>
                    <p class="text-center m-0 text-muted">
                      Balance Quota<br /><span class="h2 text-dark">{{
                        this.autoBallot.data.TotBQ
                      }}</span>
                    </p>
                    <p
                      class="text-center m-0 text-muted"
                      title="Unassigned / Balance Quota = Ratio"
                    >
                      Ratio<br /><span class="h2 text-dark">{{
                        this.autoBallot.data.totUN / this.autoBallot.data.TotBQ
                          | number
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="border-theme rounded">
                <div class="table-responsive table-sticky">
                  <table
                    class="table table-striped border-left m-0"
                    *ngIf="!autoBallot"
                  >
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          class="fix border-bottom-0 border-top-0"
                        >
                          OPS GROUP
                        </th>
                        <th
                          scope="col"
                          class="fix border-bottom-0 border-top-0"
                        >
                          OPS TEAM
                        </th>
                        <th
                          scope="col"
                          class="fix border-bottom-0 border-top-0"
                        >
                          ADD TO ALL SLOTS
                        </th>
                        <th
                          scope="col"
                          class="noWrap"
                          *ngFor="let item of slotWeeks; let i = index"
                        >
                          SLOT {{ i + 1 }}<br />
                          <span class="h6 text-muted">
                            <ng-container
                              *ngIf="ballotdata.get('leaveType').value == '1'"
                            >
                              {{ item[0] | date: "dd MMM" }} -
                              {{ item[1] | date: "dd MMM" }}
                            </ng-container>
                            <ng-container
                              *ngIf="ballotdata.get('leaveType').value == '2'"
                            >
                              {{ item | date: "dd MMM" }}</ng-container
                            >
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of allOps; let ind = index">
                        <th scope="row" class="fix">{{ item.OpsGrp }}</th>
                        <td class="fix">{{ item.OpsTeam }}</td>
                        <td class="fix">
                          <div class="align-items-center d-flex">
                            <input
                              type="number"
                              class="form-control mr-3"
                              [(ngModel)]="slot[ind]"
                              [ngModelOptions]="{ standalone: true }"
                            />
                            <input
                              type="checkbox"
                              (change)="checkAllSlots($event, ind)"
                            />
                          </div>
                        </td>
                        <!-- this.emptySlots.includes('S'+ind+'T'+i) -->
                        <td *ngFor="let el of slotWeeks; let i = index">
                          <input
                            type="number"
                            class="form-control"
                            [class.is-invalid]="(item.slots[i] === null || item.slots[i] === undefined) && slotQuotaSetupSubmitted"
                            [(ngModel)]="item.slots[i]"
                            [ngModelOptions]="{ standalone: true }"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table
                    class="table table-striped border-left m-0"
                    *ngIf="autoBallot"
                  >
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          class="fix border-bottom-0 border-top-0"
                        >
                          OPS GROUP
                        </th>
                        <th
                          scope="col"
                          class="fix border-bottom-0 border-top-0"
                        >
                          OPS TEAM
                        </th>
                        <th
                          scope="col"
                          class="fix border-bottom-0 border-top-0"
                        >
                          TOTAL
                          <div>
                            <span class="box-label text-muted">BQ</span>
                            <span class="box-label text-muted ml-1">U</span>
                          </div>
                        </th>
                        <th
                          scope="col"
                          class="noWrap"
                          *ngFor="let item of slotWeeks; let i = index"
                        >
                          SLOT {{ i + 1 }}<br />
                          <span class="h6 text-muted">
                            <ng-container
                              *ngIf="ballotdata.get('leaveType').value == '1'"
                            >
                              {{ item[0] | date: "dd MMM" }} -
                              {{ item[1] | date: "dd MMM" }}
                            </ng-container>
                            <ng-container
                              *ngIf="ballotdata.get('leaveType').value == '2'"
                            >
                              {{ item | date: "dd MMM" }}</ng-container
                            >
                          </span>
                          <div>
                            <span class="box-label text-muted">BQ</span>
                            <span class="box-label text-muted ml-1">TBA</span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of allOps; let ind = index">
                        <th scope="row" class="fix">{{ item.OpsGrp }}</th>
                        <td class="fix">{{ item.OpsTeam }}</td>
                        <td class="fix">
                          <div class="d-flex">
                            <input
                              type="text"
                              class="form-control box"
                              value="{{ item.BallotBalance }}"
                              readonly
                            />
                            <input
                              type="text"
                              class="form-control box ml-1"
                              value="{{ item.unassignBalanace }}"
                              readonly
                            />
                          </div>
                        </td>

                        <td *ngFor="let el of slotWeeks; let i = index">
                          <div class="d-flex">
                            <input
                              type="text"
                              class="form-control box"
                              [(ngModel)]="item.slots[i][0]"
                              [ngModelOptions]="{ standalone: true }"
                              readonly
                            />
                            <input
                              type="text"
                              class="form-control box ml-1"
                              [(ngModel)]="item.slots[i][1]"
                              [ngModelOptions]="{ standalone: true }"
                              readonly
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <hr />

          <div class="row" *ngIf="autoBallot">
            <div class="col-md-12">
              <div class="card border-theme">
                <div class="card-header">
                  ELIGIBLE STAFF FOR AUTO-ASSIGNMENT
                </div>
                <div class="card-body p-0">
                  <table class="table" *ngIf="autoBallotStaff">
                    <thead>
                      <tr>
                        <th>STAFF NAME</th>
                        <th>BALLOT BALANCE</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of this.autoBallotStaff.data">
                        <td>{{ item.name }}</td>
                        <td>{{ item.ballotLeaveBalance }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="d-flex">
            <button
              type="button"
              class="align-items-center btn btn-outline-dark d-flex"
              matStepperPrevious
            >
              <span class="material-icons mr-1"> arrow_back </span>
              <span>Previous</span>
            </button>
            <button
              type="button"
              class="align-items-center bg-theme btn d-flex ml-2"
              (click)="slotQuotaSetupNext()"
            >
              <span>Next</span>
              <span class="material-icons ml-1"> arrow_forward </span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="restrictLimit">
    <form [formGroup]="restrictLimit">
      <ng-template matStepLabel>RESTRICTIONS & LIMITS </ng-template>

      <div class="card">
        <div class="card-header bg-theme">RESTRICTIONS & LIMITS</div>
        <div class="card-body">
          <div class="row my-3">
            <div class="col-md-12">
              <div class="form-group m-0">
                <label for="" class="mr-4 font-weight-500">
                  Do you need to restrict ballot submission?<span class="field-mandatory">*</span>
                </label>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="customRadioInline12"
                    name="isRestrict"
                    class="custom-control-input"
                    formControlName="isRestrict"
                    value="true"
                  />
                  <label class="custom-control-label" for="customRadioInline12"
                    >Yes</label
                  >
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="customRadioInline24"
                    name="isRestrict"
                    class="custom-control-input"
                    formControlName="isRestrict"
                    value="false"
                  />
                  <label class="custom-control-label" for="customRadioInline24"
                    >No</label
                  >
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="restrictLimit.get('isRestrict').value == 'true'">
            <div class="row my-3">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header bg-theme">
                    BY MAXIMUM SUBMISSION PER SEGMENT
                  </div>
                  <div class="card-body p-0">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">SEGMENT NO</th>
                            <th scope="col">START DATE</th>
                            <th scope="col">END DATE</th>
                            <th scope="col">MAXIMUM SLOT PER STAFF</th>
                            <th scope="col">ACTIONS</th>
                          </tr>
                        </thead>
                        <tbody formArrayName="maxSegment">
                          <tr
                            *ngFor="
                              let seg of maxSegment.controls;
                              let i = index
                            "
                          >
                            <ng-container [formGroupName]="i">
                              <td>{{ i + 1 }}</td>
                              <td>
                                <input
                                  class="form-control"
                                  (click)="d5.toggle()"
                                  placeholder="Select date"
                                  ngbDatepicker
                                  [minDate]="minSegmentDate"
                                  [maxDate]="maxSegmentDate"
                                  #d5="ngbDatepicker"
                                  formControlName="startDate"
                                />
                              </td>
                              <td>
                                <input
                                  class="form-control"
                                  (click)="d6.toggle()"
                                  placeholder="Select date"
                                  ngbDatepicker
                                  [minDate]="maxSegment.at(i).get('startDate').value"
                                  [maxDate]="maxSegmentDate"
                                  #d6="ngbDatepicker"
                                  formControlName="endDate"
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control"
                                  formControlName="maxBallot"
                                />
                              </td>
                              <td>
                                <button
                                  class="btn mr-2 p-0"
                                  title="Delete"
                                  (click)="removeMaxSegment(i)"
                                  [disabled]="autoBallot?.data?.isAutoAssign || roundBallot"
                                >
                                  <span class="material-icons text-danger"
                                    >clear</span
                                  >
                                </button>
                                <button
                                  *ngIf="!seg.disabled"
                                  class="btn mr-2 p-0"
                                  title="Add"
                                  (click)="plusSegment(i)"
                                  [disabled]="!seg.valid || autoBallot?.data?.isAutoAssign || roundBallot"
                                >
                                  <span class="material-icons text-success"
                                    >add</span
                                  >
                                </button>
                                <button
                                  *ngIf="seg.disabled"
                                  class="btn p-0"
                                  title="Edit"
                                  (click)="editSegment(i)"
                                  [disabled]="autoBallot?.data?.isAutoAssign || roundBallot"
                                >
                                  <span class="material-icons text-primary"
                                    >edit</span
                                  >
                                </button>
                              </td>
                            </ng-container>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button
                      type="button"
                      class="btn btn-primary mt-2"
                      (click)="addSegment()"
                      [disabled]="autoBallot?.data?.isAutoAssign || roundBallot"
                    >
                      Add segment
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row my-3">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header bg-theme">
                    BY MAXIMUM CONSECUTIVE BALLOTS
                  </div>
                  <div class="card-body">
                    <div class="form-group m-0">
                      <label class="font-weight-500"
                        >Maximum consecutive ballots per staff:</label
                      >
                      <input
                        type="text"
                        class="form-control w-25"
                        formControlName="maxConsecutiveBallot"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row my-3">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header bg-theme">BY STAFF RESTRICTION</div>
                  <div class="card-body">
                    <div class="form-row">
                      <div class="form-group m-0 col-md-3">
                        <label class="font-weight-500">Select slot</label>
                        <ng-select
                          [multiple]="false"
                          [closeOnSelect]="true"
                          [searchable]="false"
                          placeholder="Select Slot"
                          [(ngModel)]="slotformat"
                          [ngModelOptions]="{ standalone: true }"
                          (change)="resSlotChange()"
                          [disabled]="roundBallot || autoBallot?.data?.isAutoAssign"

                        >
                          <ng-option
                            *ngFor="let item of slotWeeks; let i = index"
                            [value]="item"
                          >
                            <ng-template
                              [ngIf]="ballotdata.get('leaveType').value == '1'"
                            >
                              Slot--{{ i + 1 }} {{ item[0] | date: "dd-MMM" }} --
                              {{ item[1] | date: "dd-MMM" }}
                            </ng-template>
                            <ng-template
                              [ngIf]="ballotdata.get('leaveType').value == '2'"
                            >
                              Slot--{{ i + 1 }} {{ item | date: "dd-MMM" }}
                            </ng-template>
                          </ng-option>
                        </ng-select>
                        <div *ngIf="addStaffFlag && !slotformat" class="field-mandatory">Please select slot</div>
                      </div>
                      <div class="form-group m-0 col-md-3">
                        <label for="" class="d-block font-weight-500"
                          >Select Ops Group</label
                        >

                        <div
                          class="form-check form-check-inline"
                          *ngFor="let ops of opsGrp; let oi = index"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="gridCheck{{ oi }}"
                            [(ngModel)]="ops.isSelected"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="selectedResGrp($event, oi,ops)"
                            [disabled]="roundBallot || autoBallot?.data?.isAutoAssign"
                          />
                          <label
                            class="form-check-label"
                            for="gridCheck{{ oi }}"
                          >
                            {{ ops.opsGroupName }}
                          </label>
                        </div>
                        <div *ngIf="addStaffFlag && checkOpsGrop()" class="field-mandatory">Please select Ops group</div>
                      </div>
                      <div class="form-group m-0 col-md-3">
                        <label
                          *ngIf="allTeam.length"
                          class="d-block font-weight-500"
                          >Select Ops Team</label
                        >

                        <div
                          class="form-check form-check-inline"
                          *ngFor="let team of allTeam; let ti = index"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="gridCheck{{ ti }}"
                            [(ngModel)]="team.isSelected"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="selectStaff($event, ti,team)"
                          />
                          <label
                            class="form-check-label"
                            for="gridCheck{{ ti }}"
                          >
                            {{ team.name }}
                          </label>
                        </div>
                      </div>
                      <div
                        class="form-group m-0 col-md-3"
                        *ngIf="allStaff.length"
                      >
                        <label class="font-weight-500">Select Staff</label>
                        <ng-select
                          [multiple]="true"
                          [closeOnSelect]="false"
                          [searchable]="true"
                          placeholder="Select Staff"
                          [(ngModel)]="resStaff"
                          clearAllText="Clear"
                          [ngModelOptions]="{ standalone: true }"
                        >
                          <ng-option
                            *ngFor="let item of allStaff"
                            [value]="item"
                          >
                            {{ item.name }}</ng-option
                          >
                        </ng-select>
                        <div *ngIf="addStaffFlag && !resStaff" class="field-mandatory">Please select staff</div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button
                      class="btn btn-success"
                      [disabled]="autoBallot?.data?.isAutoAssign || roundBallot"
                      (click)="addResStaff()"
                    >
                      Add Staff
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="resStaffArr.length">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table border">
                    <thead>
                      <tr>
                        <th scope="col">SLOTS</th>
                        <th scope="col">RESTRICTED STAFF</th>
                        <th scope="col">REMOVE</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of resStaffArr; let in = index">
                        <td>
                         {{item.slot}}
                        </td>
                        <td class="comma">
                          <span *ngFor="let el of item.userList">{{
                            el.name
                          }}</span>
                        </td>
                        <td>
                          <button
                          class="btn mr-2 p-0"
                          title="Delete"
                          (click)="removeStaff(in)"
                          [disabled]="autoBallot?.data?.isAutoAssign || roundBallot"
                        >
                          <span class="material-icons text-danger"
                            >clear</span
                          >
                        </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="card-footer">
          <div class="d-flex">
            <button
              type="button"
              class="align-items-center btn btn-outline-dark d-flex"
              matStepperPrevious
            >
              <span class="material-icons mr-1"> arrow_back </span>
              <span>Previous</span>
            </button>
            <button
              type="button"
              class="align-items-center bg-theme btn d-flex ml-2"
              (click)="stepper.next()"
            >
              <span>Next</span>
              <span class="material-icons ml-1"> arrow_forward </span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="admin">
    <form [formGroup]="admin">
      <ng-template matStepLabel>ADMIN </ng-template>

      <div class="card">
        <div class="card-header bg-theme">ADMIN</div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group m-0">
                <label class="font-weight-500">Select Admin<span class="field-mandatory">*</span></label>
                <ng-select
                  (change)="addLoginFields($event)"
                  class="custom"
                  bindLabel="name"
                  placeholder="Select Admin"
                  appendTo="body"
                  [multiple]="false"
                  [searchable]="true"
                  [clearable]="true"
                  [loading]="CustomFieldDropDownLoading"
                  [virtualScroll]="true"
                  (clear)="onClearCustomFieldDropdown($event)"
                >
                  <ng-option
                    [disabled]="autoBallot?.data?.isAutoAssign"
                    [value]="item"
                    *ngFor="let item of fieldNeedsToUpdate"
                  >
                    {{ item.name }}
                  </ng-option>
                </ng-select>
                <!-- (search)="onSeachCustomField($event)"
                  (scrollToEnd)="loadMoreCustomFieldItems($event)"

                  (clear)="onClearCustomFieldDropdown($event)" -->
                <!-- <ng-select
                  [items]="admins"
                  [multiple]="true"
                  [closeOnSelect]="false"
                  [searchable]="false"
                  placeholder="Select Admin"
                  formControlName="adminId"
                >
                </ng-select> -->
              </div>
            </div>
            <div class="col-md-6 align-self-end">
              <div
                class="d-inline-block"
                formArrayName="adminId"
                *ngFor="
                  let field of LoginFieldsControl.controls;
                  let index = index
                "
              >
                <span
                  class="align-items-center bg-theme d-flex h5 justify-content-around px-3 py-2 rounded mr-2 mb-0"
                  [formGroupName]="index"
                >
                  <span>
                    {{ field.get("string").value }}
                  </span>

                  <button
                    class="btn p-0 material-icons text-danger ml-2 click"
                    [disabled]="autoBallot?.data?.isAutoAssign"
                    (click)="deleteSelectedFields(index)"
                  >
                    clear
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="d-flex">
            <button
              type="button"
              class="align-items-center btn btn-outline-dark d-flex"
              matStepperPrevious
            >
              <span class="material-icons mr-1"> arrow_back </span>
              <span>Previous</span>
            </button>
            <button
              type="button"
              class="align-items-center bg-theme btn d-flex ml-2"
              (click)="stepper.next();"
            >
              <span>Next</span>
              <span class="material-icons ml-1"> arrow_forward </span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>SAVE/PUBLISH </ng-template>
    <div class="card">
      <!--<div class="loading-class" *ngIf="loader">
        <div class="loading">Loading...</div>
        <div class="text-center" style="font-size: 20px;">Please wait, data is loading...</div>
      </div>-->
      <div *ngIf="loader" style="position: fixed;top: 0;right: 0;bottom: 0;left: 0;z-index: 1050;background-color: rgba(0, 0, 0, 0.5);overflow: auto;">
        <div class="pageLoading" style="text-align: center;color: white;font-size: 450%;">
            <div class="innerDiv">
                <i class="fa fa-spinner fa-spin" style="float: none;"></i> Please wait Loading Data...
            </div>
        </div>
    </div>
      <div class="card-header bg-theme">SAVE/PUBLISH</div>
      <div class="card-body">
        <div class="row mb-3">
          <div class="col-md-6">
            <table class="table border m-0">
              <tr>
                <th>BALLOT NAME</th>
                <td>{{ this.ballotdata?.controls.ballotName?.value }}</td>
              </tr>
              <tr>
                <th>BALLOT FOR PERIOD</th>
                <td>
                  {{ this.ballotdata.get("ballotStartDate").value?.day }}/{{
                    this.ballotdata.get("ballotStartDate").value?.month
                  }}/{{
                    this.ballotdata?.get("ballotStartDate").value?.year
                  }}
                  to {{ this.ballotdata.get("ballotEndDate").value?.day }}/{{
                    this.ballotdata.get("ballotEndDate").value?.month
                  }}/{{ this.ballotdata.get("ballotEndDate").value?.year }}
                </td>
              </tr>
              <tr>
                <th>OPENING DATE FOR APPLICATION</th>
                <td>
                  {{ this.ballotdata.get("openDate").value?.day }}/{{
                    this.ballotdata.get("openDate").value?.month
                  }}/{{ this.ballotdata.get("openDate").value?.year }}
                  {{ this.ballotdata.get("openTime").value }}
                </td>
              </tr>
              <tr>
                <th>CLOSING DATE FOR APPLICATION</th>
                <td>
                  {{ this.ballotdata.get("closeDate").value?.day }}/{{
                    this.ballotdata.get("closeDate").value?.month
                  }}/{{ this.ballotdata.get("closeDate").value?.year }}
                  {{ this.ballotdata.get("closeTime").value }}
                </td>
              </tr>
              <tr>
                <th>BALLOT STATUS</th>
                <td>
                  {{
                    this.ballotdata.get("resultRelease").value == "1"
                      ? "Auto"
                      : "Manual"
                  }}
                </td>
              </tr>
              <tr>
                <th>BALLOT TYPE</th>
                <td>
                  {{
                    this.ballotdata.get("leaveType").value == 1
                      ? "Week Ballot Slot"
                      : "Day Ballot Slot"
                  }}
                </td>
              </tr>
              <tr *ngIf="this.ballotdata.get('fixedBallotingLeaveType').value">
                <th>Leave Type / Quota</th>
                <td>
                  <ng-container *ngIf="this.ballot">
                    {{ this.ballot.leaveTypeId?.name }} /
                  </ng-container>
                  Quota:
                  {{ this.ballotdata.get("totalQuota").value }}
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header bg-theme">SLOT VIEW</div>
              <div class="card-body">
                <div class="border-theme rounded">
                  <div class="table-responsive  table-clot-view">
                    <table class="table table-striped m-0" *ngIf="!autoBallot">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            class="fix border-bottom-0 border-top-0"
                          >
                            OPS GROUP
                          </th>
                          <th
                            scope="col"
                            class="fix border-bottom-0 border-top-0"
                          >
                            OPS TEAM
                          </th>
                          <!-- <th
                            scope="col"
                            class="fix border-bottom-0 border-top-0"
                          >
                            ADD TO ALL SLOTS
                          </th> -->
                          <th
                            scope="col"
                            class="noWrap"
                            *ngFor="let item of slotWeeks; let i = index"
                          >
                            SLOT {{ i + 1 }}<br />
                            <span class="h6 text-muted">
                              <ng-container
                                *ngIf="ballotdata.get('leaveType').value == '1'"
                              >
                                {{ item[0] | date: "dd MMM" }} -
                                {{ item[1] | date: "dd MMM" }}
                              </ng-container>
                              <ng-container
                                *ngIf="ballotdata.get('leaveType').value == '2'"
                              >
                                {{ item | date: "dd MMM" }}</ng-container
                              >
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of allOps; let ind = index">
                          <th scope="row" class="fix">{{ item.OpsGrp }}</th>
                          <td class="fix">{{ item.OpsTeam }}</td>
                          <!-- <td class="fix">
                             <div class="align-items-center d-flex">
                              <input
                                type="text"
                                class="form-control mr-3"
                                [(ngModel)]="slot[ind]"
                                [ngModelOptions]="{ standalone: true }"
                                readonly
                              />
                            </div>
                          </td> -->

                          <td *ngFor="let el of slotWeeks; let i = index">
                            <input
                              type="text"
                              class="form-control"
                              [(ngModel)]="item.slots[i]"
                              [ngModelOptions]="{ standalone: true }"
                              readonly
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table
                      class="table table-striped border-left m-0"
                      *ngIf="autoBallot"
                    >
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            class="fix border-bottom-0 border-top-0"
                          >
                            OPS GROUP
                          </th>
                          <th
                            scope="col"
                            class="fix border-bottom-0 border-top-0"
                          >
                            OPS TEAM
                          </th>
                          <th
                            scope="col"
                            class="fix border-bottom-0 border-top-0"
                          >
                            TOTAL
                            <div>
                              <span class="box-label text-muted">BQ</span>
                              <span class="box-label text-muted ml-1">U</span>
                            </div>
                          </th>
                          <th
                            scope="col noWrap"
                            *ngFor="let item of slotWeeks; let i = index"
                          >
                            SLOT {{ i + 1 }}<br />
                            <span class="h6 text-muted">
                              <ng-container
                                *ngIf="ballotdata.get('leaveType').value == '1'"
                              >
                                {{ item[0] | date: "dd MMM" }} -
                                {{ item[1] | date: "dd MMM" }}
                              </ng-container>
                              <ng-container
                                *ngIf="ballotdata.get('leaveType').value == '2'"
                              >
                                {{ item | date: "dd MMM" }}
                              </ng-container>
                            </span>

                            <div>
                              <span class="box-label text-muted">BQ</span>
                              <span class="box-label text-muted ml-1">TBA</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of allOps; let ind = index">
                          <th scope="row" class="fix">{{ item.OpsGrp }}</th>
                          <td class="fix">{{ item.OpsTeam }}</td>
                          <td class="fix">
                            <div class="d-flex">
                              <input
                                type="text"
                                class="form-control box"
                                value="{{ item.BallotBalance }}"
                                readonly
                              />
                              <input
                                type="text"
                                class="form-control box ml-1"
                                value="{{ item.unassignBalanace }}"
                                readonly
                              />
                            </div>
                          </td>

                          <td *ngFor="let el of slotWeeks; let i = index">
                            <div class="d-flex">
                              <input
                                type="text"
                                class="form-control box"
                                [(ngModel)]="item.slots[i][0]"
                                [ngModelOptions]="{ standalone: true }"
                                readonly
                              />
                              <input
                                type="text"
                                class="form-control box ml-1"
                                [(ngModel)]="item.slots[i][1]"
                                [ngModelOptions]="{ standalone: true }"
                                readonly
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="row">
          <div
            class="col-md-12 mb-3"
            *ngIf="this.restrictLimit.get('maxSegment').value?.length != 0"
          >
            <div class="card">
              <div class="card-header bg-theme">
                MAXIMUM SUBMISSIONS IN A SEGMENT
              </div>
              <div class="card-body p-0">
                <div class="table-responsive">
                  <table class="table m-0">
                    <thead>
                      <tr>
                        <th scope="col">SEGMENT NO</th>
                        <th scope="col">START DATE</th>
                        <th scope="col">END DATE</th>
                        <th scope="col">MAXIMUM SLOT PER STAFF</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let item of this.restrictLimit.get('maxSegment').value
                        "
                      >
                        <td>{{ item.segmentNo }}</td>
                        <td>
                          {{ item.startDate.day }}-{{ item.startDate.month }}-{{
                            item.startDate.year
                          }}
                        </td>
                        <td>
                          {{ item.endDate.day }}-{{ item.endDate.month }}-{{
                            item.endDate.year
                          }}
                        </td>
                        <td>{{ item.maxBallot }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-md-12 mb-3"
            *ngIf="this.restrictLimit.get('maxConsecutiveBallot').value"
          >
            <div class="card">
              <div class="card-header bg-theme">
                MAXIMUM CONSECUTIVE BALLOTS
              </div>
              <div class="card-body">
                <div class="form-group m-0">
                  <label class="font-weight-500"
                    >Maximum consecutive ballots per staff</label
                  >
                  <input
                    type="text"
                    class="form-control w-25"
                    [value]="
                      this.restrictLimit.get('maxConsecutiveBallot').value
                    "
                    readonly
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 mb-3" *ngIf="resStaffArr?.length">
            <div class="card">
              <div class="card-header bg-theme">
                STAFF RESTRICTED FROM PARTICULAR WEEKS
              </div>
              <div class="card-body p-0">
                <div class="table-responsive">
                  <table class="table m-0">
                    <thead>
                      <tr>
                        <th scope="col">SELECTED DATE</th>
                        <th scope="col">STAFF LIST</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of resStaffArr">
                        <td>
                         {{item.slot}}
                        </td>
                        <td class="comma">
                          <span *ngFor="let el of item.userList">
                            {{ el.name }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="d-flex">
          <button
            type="button"
            class="align-items-center btn btn-outline-dark d-flex"
            matStepperPrevious
          >
            <span class="material-icons mr-1"> arrow_back </span>
            <span>Previous</span>
          </button>
          <button
            type="button"
            class="align-items-center btn-info btn d-flex ml-2"
            (click)="publishBallot(true)"
            *ngIf="!this.ballot"  [disabled]="saveAsDraftLoader"
          >
            <span>Save as Draft</span>
            <span class="material-icons ml-2"> save </span>
            <span class="material-icons ml-2" *ngIf="!saveAsDraftLoader">
              arrow_upward
            </span>
            <span
              *ngIf="saveAsDraftLoader"
              class="spinner-border spinner-border-sm ml-2"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
          <button
            type="button"
            class="align-items-center btn-success btn d-flex ml-2"
            (click)="publishBallot()"
            [disabled]="publishBallotLoader"
          >
            <span *ngIf="!this.roundBallot && !this.autoBallot">{{
              this.ballot ? "Update" : "Publish"
            }}</span>
            <span *ngIf="this.roundBallot">Create Reballot</span>
            <span *ngIf="this.autoBallot">Create Auto-Assign ballot</span>
            <span class="material-icons ml-2" *ngIf="!publishBallotLoader">
              arrow_upward
            </span>
            <span
              *ngIf="publishBallotLoader"
              class="spinner-border spinner-border-sm ml-2"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
        </div>
      </div>
    </div>
  </mat-step>
</mat-horizontal-stepper>
