import { map } from 'rxjs/operators';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomFormService } from 'src/app/users/service/custom-form/custom-form.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Component({
  selector: 'edit-response',
  templateUrl: './edit-response.html',
  styleUrls: ['./edit-response.scss'],
})
export class EditResponseComponent implements OnInit {
  @Input() rowData: any;
  @Input() responseId: any;
  @Input() tableHeaders: string[];
  @Input() metaData: any;
  @Output() onSave = new EventEmitter<any>();
  @Output() onClose = new EventEmitter<void>();
  public isModuleView: boolean = false;
  viewQuestions: any = [];
  editForm: FormGroup;
  selectedModule;
  sectionList;
  customFormId;
  maxDate = {year: new Date().getFullYear(), month: new Date().getMonth()+1, day: new Date().getDate()};
  minDate;
  serviceStartDateTime;
  isCorrectlyPopulated = 0
  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private customFormService: CustomFormService,
    private datePipe: DatePipe,
    private toastService: ToastrService
  ) {}

  ngOnInit(): void {
    console.log(
      'edit-response',
      this.rowData,
      this.tableHeaders,
      this.metaData
    );
    console.log('edit-response', this.responseId);
    if (this.responseId) this.getQuestions(this.responseId);

    this.editForm = this.fb.group({});
    this.tableHeaders.forEach((header) => {
      if (
        header === 'Staff Id' ||
        header === 'Staff Name' ||
        (this.rowData[header].questionId === this.metaData?.firstQuestionId &&
          this.metaData?.formSubTypeId)
      ) {
        // Disable the fields that should not be edited
        this.editForm.addControl(
          header,
          this.fb.control({ value: this.rowData[header].value, disabled: true })
        );
      } else {
        // Keep the other fields editable
        this.editForm.addControl(
          header,
          this.fb.control(this.rowData[header].value)
        );
      }
    });
  }

  getQuestions(moduleId: string) {
    this.customFormService
      .getSubmittedFormDataAdmin(moduleId)
      .subscribe((data) => {
        this.isModuleView = true;
        this.customFormId = data?.customFormId;
        this.selectedModule = data || null;
        this.sectionList = this.selectedModule.sections || [];

        let allQuestions = data.sections.reduce((acc, section) => {
          return acc.concat(section.questions);
        }, []);

        allQuestions = allQuestions.filter((v) => 7 !== v.type) || [];
        this.viewQuestions =
          allQuestions.filter((x) => 0 === x.conditionalQuestions.length) || [];

        let typeFourteen = null;
        this.viewQuestions?.map((item) => {
          item.answer = item.value || '';
          if (item.type === 10) {
            item.answer = {};
            item.answer['date'] = null;
            item.answer['time'] = null;
          }
          if (item.type === 14) {
            typeFourteen = item._id;
          }
          if (item.type === 15) {
            item?.options.map((final: any, index) => {
              item['images'][index] = {
                src: final.imageSrc,
                value: final.value,
                option: final._id,
              };
            });
          }
        });
        console.log(this.viewQuestions, 'his.viewQuestions');
        // typeFourteen && this.getNominateUserType(typeFourteen);
        if(this.metaData.formSubTypeId){
          this.serviceStartDateTime = new Date(this.viewQuestions[0].value);
          this.minDate = {year: this.serviceStartDateTime.getFullYear(), month: this.serviceStartDateTime.getMonth()+1, day: this.serviceStartDateTime.getDate()}

          console.log(this.minDate, 'this.minDate');
        }
        this.storeOriginalValues();
      });
  }

  convert24hourTo12HourFormat(time) {
    const time_part_array = time.split(':');
    let ampm = 'am';
    if (time_part_array[0] >= 12) {
      ampm = 'pm';
    }
    if (time_part_array[0] > 12) {
      time_part_array[0] = time_part_array[0] - 12;
    }
    const formatted_time =
      time_part_array[0] + ':' + time_part_array[1] + ':' + ampm;
    return formatted_time;
  }

  convertDateTimeIntoHHMM(originalDate) {
    return moment(originalDate).format('DD MMM YYYY HH:mm');
  }

  removeHtmlTags(str: string): string {
    return str.replace(/<\/?[^>]+(>|$)/g, "");
  }

  onInputChangeShortText(event: any, isNumeric: boolean) {
    if (isNumeric) {
      // Allow only numeric keys, backspace, tab, arrow keys, and delete
      const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];

      // Check if the key pressed is a number (0-9) or allowed control keys
      if (!allowedKeys.includes(event.key) && !(event.key >= '0' && event.key <= '9')) {
        // Allow Ctrl+V or Cmd+V for paste operation
        if (!(event.ctrlKey && event.key === 'v') && !(event.metaKey && event.key === 'v')) {
          event.preventDefault();
        }
      }
    }
  }

  onPaste(event: ClipboardEvent, isNumeric: boolean, len) {
    if (isNumeric) {
      const pastedData = event.clipboardData?.getData('text') || '';


      // Allow only numeric pasted data
      if (!/^\d+$/.test(pastedData) || pastedData.length !== len) {
        event.preventDefault(); // Prevent non-numeric characters
      }
    }
  }

  submit() {
    const mandatoryQuestion = this.viewQuestions.filter((data) => {
      if (data?.required) return data?._id;
    });
    const answers = this.viewQuestions.reduce((prev, question, i) => {
      let answer = null,
        _id = question._id,
        type = question.type,
        required = question?.required,
        sectionId = question?.sectionId,
        questionId = question.questionId,
        options = question?.options || null ,
        originalAnswer = question?.originalAnswer,
        isNumeric= question?.isNumeric,
        maxlength=question?.maxlength,
        questionTitle = question?.question;


      switch (question.type) {
        case 1:
        case 2:
        case 3:
        case 6:
        case 8:
        case 11:
          answer = question.answer
          question.value = answer
          const option = question.options.find(item => item.value === answer)
          if(option){
            question.option = option._id
          }
          break;
        case 14:
        case 12:
        case 13:
        case 16:
        case 15:
        case 21:
          answer = question.answer;
          break;
        case 22:
          answer = question.answer && question.answer.includes('T')? this.convertDateTimeIntoHHMM(question.answer): question.answer;
          break;
        case 9:
          answer = question.answer?.toString();
          break;
        case 10:
          if (
            -1 !== question.dateTime.indexOf(1) &&
            !!question.answer &&
            !!question.answer.date
          ) {
            answer = answer || {};
            answer['date'] = this.datePipe.transform(
              new Date(question.answer.date),
              'dd-MM-yyyy'
            );
          }

          if (
            -1 !== question.dateTime.indexOf(2) &&
            !!question.answer &&
            !!question.answer.time
          ) {
            answer = answer || {};
            if (question?.answer.time?.hour && question?.answer.time?.minute) {
              answer['time'] = this.convert24hourTo12HourFormat(
                question.answer.time?.hour + ':' + question.answer.time?.minute
              );
            }
          }
          if (
            -1 !== question.dateTime.indexOf(1) &&
            -1 !== question.dateTime.indexOf(2) &&
            !(
              !!question.answer &&
              !!question.answer.date &&
              !!question.answer.time?.hour &&
              !!question.answer.time?.minute
            )
          ) {
            answer = false;
          }
          break;
        case 5:
        case 4:
          answer = question.options?.filter((v) => v.answer);
          if (!answer.length) {
            answer = null;
          }
          break;
        default:
          answer = false;
          break;
      }
      return prev.concat({
        _id,
        answer,
        type,
        required,
        sectionId,
        questionId,
        options,
        originalAnswer,
        questionTitle,
        isNumeric,
        maxlength
      });
    }, []);
    if (answers?.some((v) => v.type === 9 && !!!v.answer)) {
      this.toastService.error('Please enter valid numeric values');
      return;
    }

    if (
      answers.some(
        (v) =>
          mandatoryQuestion.findIndex((el) => el._id == v._id) > -1 &&
          !!!v.answer
      )
    ) {
      this.toastService.error('Answer all mandatory questions');
      return;
    }
    let answerList = answers?.map((val) => ({
      sectionId: val?.sectionId,
      questionId: val.questionId,
      option: typeof val?.answer !== 'object' ? null : val?.answer?._id,
      value: typeof val?.answer !== 'object' ? val?.answer : val?.answer?.value,
      type: val?.type,
    }));
    let attendanceLabel = null;
    let isError: any = false
    answers?.forEach((item)=>{
      if(item.type === 22){
        if(item.answer !== item.originalAnswer){
          attendanceLabel = item.questionTitle
        }
      }
      if(item.type === 8 && item.isNumeric){
        isError = !this.isNumeric(item.answer) || item.answer?.length !== item.maxlength ? 'Please enter valid postal code': false
        delete item.isNumeric
        delete item.maxlength
      }

    });
    if(isError){
      this.toastService.error(isError);
       return;
    }
    if (this.metaData.formSubTypeId) {
      answerList = answerList.filter((val) => {
        return val.questionId !== this.metaData.firstQuestionId;
      });
    }
    const mainPayload = {
      answers: answerList,
      attendanceLabel: attendanceLabel? this.removeHtmlTags(attendanceLabel): null,
      isCorrectlyPopulated: this.isCorrectlyPopulated,
    };
    answers.length > 0
      ? this.customFormService
          .resubmitForm(mainPayload, this.responseId)
          .subscribe(
            (data) => {
              this.toastService.success('Form Re-submitted Successfully');
              this.onSave.emit();
              this.cancelPopup();
            },
            (error) => {
              this.toastService.error(error?.error?.error?.message);
            }
          )
      : '';
  }

  isNumeric(str) {
    return /^[0-9]+$/.test(str);
  }

  isControlModified(controlName: string): boolean {
    const control = this.editForm.get(controlName);
    return control && control.dirty;
  }

  cancelPopup() {
    this.activeModal.dismiss();
  }

  close(): void {
    this.activeModal.dismiss();
  }

  disableFirstQuestion(item) {
    console.log('item', item);
    return item.questionId == this.metaData.firstQuestionId;
  }

  storeOriginalValues(): void {
    this.viewQuestions.forEach((item) => {
      item.originalAnswer = item.answer;
      item.isDisabled =
        this.metaData?.formSubTypeId && this.disableFirstQuestion(item);
    });
  }

  generateAutoPopulateData(questionItem: any) {

    const usedForAutoPopulate = this.viewQuestions.filter((que)=> que.isUsedForAutoPopulate)
    const payload = {
      customFormId: this.customFormId,
      answers: usedForAutoPopulate.map((que)=> {
        return {

            "sectionId": que.sectionId,
            "questionId":que.questionId,
            "option":que.option,
            "value": que.answer,
            "type": que.type,
        }
      }),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      serviceTime: this.viewQuestions[0].value,
    };
    this.customFormService.generateAutoPopulateData(payload).subscribe(
      (data) => {
        console.log('data', data);
        if (data.success) {
          const autoPopulateData = data.data.filter((val) => val);
          if (autoPopulateData.length > 0) {
            this.viewQuestions.forEach((item) => {
              const autoPopulateItem = autoPopulateData.find(
                (val) => val.questionId === item.questionId
              );
              if (autoPopulateItem) {
                item.answer = autoPopulateItem.value;
              }
            });
            if (autoPopulateData.length !== data.data.length) {
              this.isCorrectlyPopulated = 1;
              this.toastService.error(
                'Some of the fields are not AutoPopulated'
              );
            } else {
              this.isCorrectlyPopulated = 2;
              this.toastService.success('Data is AutoPopulated successfully');
            }
          } else {
            this.isCorrectlyPopulated = 1;
            this.toastService.error(
              "Can't AutoPopulated Data as it is not found"
            );
          }
        }
      },
      (error) => {
        this.toastService.error(error?.error?.error?.message);
      }
    );
  }
}
