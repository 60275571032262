// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // baseUrl: 'https://api.net-roc.com',
  imageUrl: 'https://admin.net-roc.com',
  // webBaseUrl: 'https://api.net-roc.com',
  baseUrl: 'https://admin.net-roc.com',
  webBaseUrl: 'https://www.net-roc.com',
  salt: '488EC1D327BE388E7126D67D3F22D',
  froalaEditorKey:'gVG3C-8D2D2A4D5C4E4A1vd1EAJLQCVLUVBa1NXNRSSATEXD-13C3B2E2E2E3C1A2C7E2A2==',
  formServiceBaseUrl: 'https://api-form.net-roc.com',
  serviceModuleBaseUrl: 'https://api-service.net-roc.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.