import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { LeavePlannerService } from 'src/app/users/service/human-resources/leave-planner.service';
import { RoleService } from 'src/app/users/service/user-roles/role.service';
@Component({
  selector: 'app-leave-balloting',
  templateUrl: './leave-balloting.component.html',
  styleUrls: ['./leave-balloting.component.scss'],
})
export class LeaveBallotingComponent implements OnInit {
  active = 1;
  // ballot: any;
  ballotDataTable:any={
    data: [],
    page: 1,
    total: 0,
    limit: 10,
  }
  ballotData: any;
  view: boolean;
  edit: boolean;
  manage: boolean = true;
  roundBallot: any;
  auto: boolean;
  autoBallotData: any;
  ballotLoader: boolean = true;
  onlyViewAccess: boolean = true;
  onlyViewAccesstemp: boolean = true;

  constructor(
    private leaveService: LeavePlannerService,
    private roleService: RoleService
  ) {}

  ngOnInit(): void {
    this.getBallot();
    this.showOnlyView();
  }

  showOnlyView() {
    const rolePrivileges = this.roleService
      .getUserPrivilege()
      .pipe(
        finalize(() => {
          rolePrivileges.unsubscribe();
        })
      )
      .subscribe((res) => {
        this.onlyViewAccess = res.data.hasOwnProperty('viewBallot')
          ? res.data.viewBallot
          : false;

        this.onlyViewAccesstemp = this.onlyViewAccess;
      });
  }

  autoBallot(e) {
    this.auto = true;
    this.autoBallotData = e;
    this.active = 2;
  }

  fromManageToOverview() {
    this.active = 1;
    this.getBallot();
  }

  statusByTime(i) {
    return moment().isBefore(i);
  }

  manageBallot(i) {
    this.manage = true;
    this.ballotData = this.ballotDataTable.data[i];
    this.active = 3;
  }

  editBallot(i) {
    if (!i.isPublish) {
      this.edit = true;
      this.ballotData = this.ballotDataTable.data[i];
      this.active = 2;
    }
  }

  viewBallot(i) {
    this.onlyViewAccesstemp = false;
    this.view = true;
    this.ballotData = this.ballotDataTable.data[i];
    this.active = 2;
  }

  backTo(e) {
    this.onlyViewAccesstemp = this.onlyViewAccess;
    this.active = e;
    this.view = false;
    this.edit = false;
    this.auto = false;
    this.ballotData = null;
    this.autoBallotData = null;
    this.roundBallot = null;
    this.getBallot();
  }

  addBallotRound(e) {
    this.active = 2;
    this.roundBallot = e;
  }

  navChanged1(event) {
    if (event.nextId === 3 && !this.manage) {
      event.preventDefault();
    }
    if (event.nextId !== 2) {
      this.onlyViewAccesstemp = this.onlyViewAccess;
      this.view = false;
      this.edit = false;
      this.auto = false;
      this.autoBallotData = null;
      this.roundBallot = null;
      this.ballotData = null;
    }
    if (event.activeId == 3 && event.nextId == 2) {
      this.view = false;
      this.edit = false;
      this.auto = false;
      this.autoBallotData = null;
      this.roundBallot = null;
      this.ballotData = null;
    }
  }

  getBallot() {
    this.ballotLoader = true;
    const ballot = this.leaveService
      .getBallot(this.ballotDataTable.page)
      .pipe(
        finalize(() => {
          this.ballotLoader = false;
          ballot.unsubscribe();
        })
      )
      .subscribe((res:any) => {
        this.ballotDataTable.data = res?.data || [];
        this.ballotDataTable.total=res?.total || 0
        this.ballotDataTable.data?.sort((a, b) => (new Date(a.createdAt)?.getTime() < new Date(b.createdAt)?.getTime() ? 1 : -1));
      });
  }

  //method to pagination
  onPageChangeballotData(page: number) {
    this.ballotDataTable.page = page;
    this.getBallot();
  }
}
